import React, { FunctionComponent } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Card, Modal, CardHeader, Typography, CardContent, CardActions, Button } from "@mui/material";
import { DeleteCardModelProps } from "../models";

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            width: "60vw",
            "min-width": "280px",
            "max-width": "600px"
        },
        card_container: {
            display: "flex",
            "align-items": "center",
            "flex-direction": "column",
            "margin-top": "50px"
        }
    })
);

const DeleteCard: FunctionComponent<DeleteCardModelProps> = ({ openModal, selectedMedia, userId, handleCloseModal, deleteMedia, getMediaOverview }) => {
    const classes = useStyles();

    const handleDelete = async () => {
        await deleteMedia(userId, selectedMedia?.id ?? "0");
        await getMediaOverview(userId, selectedMedia?.id ?? "0");
        handleCloseModal();
    };

    return (
        <Modal
            className={classes.card_container}
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Card
                variant="outlined"
                className={classes.card}>
                <CardHeader
                    titleTypographyProps={{ color: "text.secondary" }}
                    title="Aktivität löschen"
                />
                <CardContent>
                    <Typography
                        variant="body1"
                        color="text.secondary">
                        Soll die AKtivität &quot;{selectedMedia?.name ?? "Fehler"}&quot; wirklich gelöscht werden?
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        onClick={handleDelete}
                        size="small">
                        Löschen
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={handleCloseModal}>
                        Schließen
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    );
};

export default DeleteCard;
