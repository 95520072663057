import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Button, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Serie } from "../models";
import { PageContainer } from "../hoc";
import { SerieStore, UserStore } from "../stores";
import { SerieOverview, CategoryHeader } from "../components";
import { DateTimeHelper } from "../helpers";

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            "margin-top": "20px"
        }
    })
);

const SerieOverviewContainer: FunctionComponent = () => {
    const classes = useStyles();
    const serieOverview = SerieStore((state) => state.serieOverview);
    const getSerieOverview = SerieStore((state) => state.getSerieOverview);
    const resetSerieOverview = SerieStore((state) => state.resetSerieOverview);
    const createNewSerie = SerieStore((state) => state.createNewSerie);
    const saveSerie = SerieStore((state) => state.saveSerie);
    const updateSerie = SerieStore((state) => state.updateSerie);
    const deleteSerie = SerieStore((state) => state.deleteSerie);
    const userState = UserStore((state) => state.user);
    const [startedCollapsed, setStartedCollapsed] = useState(false);
    const [availableCollapsed, setAvailableCollapsed] = useState(true);
    const [openCollapsed, setOpenCollapsed] = useState(true);
    const [doneCollapsed, setDoneCollapsed] = useState(true);
    const [filterStarted, setFilterStarted] = useState<Serie[]>([]);
    const [filterAvailable, setFilterAvailable] = useState<Serie[]>([]);
    const [filterOpen, setFilterOpen] = useState<Serie[]>([]);
    const [filterDone, setFilterDone] = useState<Serie[]>([]);
    const [filterNew, setFilterNew] = useState<Serie[]>([]);
    const openRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (serieOverview === null && userState) {
            getSerieOverview(userState.uid);
        } else {
            setFilterStarted(
                serieOverview
                    ?.filter((serie) => !serie.isDone && serie.hasStarted)
                    .sort((serieA: Serie, serieB: Serie) => DateTimeHelper.compareDates(serieA.releaseDate, serieB.releaseDate)) ?? []
            );
            setFilterAvailable(
                serieOverview
                    ?.filter(
                        (serie) =>
                            (serie.isAmazon || serie.isDisney || serie.isNetflix || serie.isSky) && !(serie.isDone || serie.hasStarted) && isReleased(serie)
                    )
                    .sort((serieA: Serie, serieB: Serie) => DateTimeHelper.compareDates(serieA.releaseDate, serieB.releaseDate)) ?? []
            );
            setFilterOpen(
                serieOverview
                    ?.filter(
                        (serie) => (!(serie.isAmazon || serie.isDisney || serie.isNetflix || serie.isSky) && !serie.isDone && serie.id) || !isReleased(serie)
                    )
                    .sort((serieA: Serie, serieB: Serie) => DateTimeHelper.compareDates(serieA.releaseDate, serieB.releaseDate)) ?? []
            );
            setFilterDone(
                serieOverview
                    ?.filter((serie) => serie.isDone)
                    .sort((serieA: Serie, serieB: Serie) =>
                        DateTimeHelper.compareDates(serieA.doneDate, serieB.doneDate, serieA.releaseDate, serieB.releaseDate, false)
                    ) ?? []
            );
            setFilterNew(serieOverview?.filter((serie) => !serie.id) ?? []);
        }
    }, [serieOverview, userState, getSerieOverview]);

    useEffect(() => {
        return () => {
            resetSerieOverview();
        };
    }, [resetSerieOverview]);

    const isReleased = (serie: Serie) => {
        const today = new Date();
        if (!serie.releaseDate) {
            return false;
        }
        const serieDate = new Date(serie.releaseDate);
        return today > serieDate;
    };

    const createNewSerieAction = () => {
        if (filterNew.length === 0) {
            createNewSerie();
        }
    };

    return (
        <PageContainer active={1}>
            <Typography
                variant="h3"
                mt={2}
                mb={2}
                color="text.primary">
                Serien
            </Typography>
            {(!serieOverview || serieOverview.length === 0) && (
                <Typography
                    variant="h4"
                    mt={2}
                    mb={2}
                    color="text.primary">
                    {!serieOverview ? "Serien werden geladen..." : "Keine Serien gefunden"}
                </Typography>
            )}
            <Button
                size="small"
                variant="outlined"
                sx={{ marginTop: "16px", marginBottom: "16px" }}
                className={classes.button}
                onClick={createNewSerieAction}>
                Serie hinzufügen
            </Button>
            {serieOverview && filterNew.length > 0 && (
                <SerieOverview
                    serieOverview={filterNew}
                    userId={userState?.uid ? userState?.uid : ""}
                    saveSerie={saveSerie}
                    updateSerie={updateSerie}
                    getSerieOverview={getSerieOverview}
                    deleteSerie={deleteSerie}
                />
            )}
            {serieOverview && filterStarted.length > 0 && (
                <>
                    <CategoryHeader
                        setCollapsed={setStartedCollapsed}
                        collapsed={startedCollapsed}
                        length={filterStarted.length}
                        title="Gestartet"
                    />
                    {!startedCollapsed && (
                        <SerieOverview
                            serieOverview={filterStarted}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveSerie={saveSerie}
                            updateSerie={updateSerie}
                            getSerieOverview={getSerieOverview}
                            deleteSerie={deleteSerie}
                        />
                    )}
                </>
            )}
            {serieOverview && filterAvailable.length > 0 && (
                <>
                    <CategoryHeader
                        setCollapsed={setAvailableCollapsed}
                        collapsed={availableCollapsed}
                        length={filterAvailable.length}
                        title="Verfügbar"
                    />
                    {!availableCollapsed && (
                        <SerieOverview
                            serieOverview={filterAvailable}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveSerie={saveSerie}
                            updateSerie={updateSerie}
                            getSerieOverview={getSerieOverview}
                            deleteSerie={deleteSerie}
                        />
                    )}
                </>
            )}
            {serieOverview && filterOpen.length > 0 && (
                <div ref={openRef}>
                    <CategoryHeader
                        setCollapsed={setOpenCollapsed}
                        collapsed={openCollapsed}
                        length={filterOpen.length}
                        title="Offen"
                    />
                    {!openCollapsed && (
                        <SerieOverview
                            serieOverview={filterOpen}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveSerie={saveSerie}
                            updateSerie={updateSerie}
                            getSerieOverview={getSerieOverview}
                            deleteSerie={deleteSerie}
                        />
                    )}
                </div>
            )}
            {serieOverview && filterDone.length > 0 && (
                <>
                    <CategoryHeader
                        setCollapsed={setDoneCollapsed}
                        collapsed={doneCollapsed}
                        length={filterDone.length}
                        title="Gesehen"
                    />
                    {!doneCollapsed && (
                        <SerieOverview
                            serieOverview={filterDone}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveSerie={saveSerie}
                            updateSerie={updateSerie}
                            getSerieOverview={getSerieOverview}
                            deleteSerie={deleteSerie}
                        />
                    )}
                </>
            )}
        </PageContainer>
    );
};

export default SerieOverviewContainer;
