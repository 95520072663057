import React, { FunctionComponent } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { CategoryHeaderProps } from "../models";

const useStyles = makeStyles(() =>
    createStyles({
        headline: {
            cursor: "pointer"
        }
    })
);

const CategoryHeader: FunctionComponent<CategoryHeaderProps> = ({ setCollapsed, collapsed, title, length }) => {
    const classes = useStyles();

    return (
        <Typography
            onClick={() => setCollapsed(!collapsed)}
            className={classes.headline}
            variant="h4"
            mt={2}
            mb={2}
            color="text.primary">
            {title} ({length})
        </Typography>
    );
};

export default CategoryHeader;
