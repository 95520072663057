import React, { FunctionComponent, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { MovieEditCard, MovieViewCard } from ".";
import { Movie, MovieOverviewProps } from "../models";

/**
 * Answer Question
 */
const MovieOverview: FunctionComponent<MovieOverviewProps> = ({ movieOverview, getMovieOverview, saveMovie, updateMovie, deleteMovie, userId }) => {
    const [movies, setMovies] = useState(movieOverview);

    useEffect(() => {
        setMovies(movieOverview);
    }, [movieOverview]);

    const setEditMode = (id: string, mode: boolean) => {
        setMovies((preValue) => {
            if (id === "") {
                getMovieOverview(userId);
            }
            return preValue.map((movie) => {
                return {
                    ...movie,
                    isEdit: movie.id === id ? mode : movie.isEdit
                };
            });
        });
    };

    return (
        <>
            <Grid
                container
                spacing={2}>
                {movies.map((movie: Movie, index: number) => (
                    <Grid
                        item
                        key={index}
                        sx={{ paddingBottom: "8px" }}
                        xs={6}
                        md={4}>
                        {movie.isEdit && (
                            <MovieEditCard
                                getMovieOverview={getMovieOverview}
                                saveMovie={saveMovie}
                                updateMovie={updateMovie}
                                setEditMode={setEditMode}
                                deleteMovie={deleteMovie}
                                movie={movie}
                                userId={userId}
                            />
                        )}
                        {!movie.isEdit && (
                            <MovieViewCard
                                movie={movie}
                                userId={userId}
                                setEditMode={setEditMode}
                                updateMovie={updateMovie}
                                getMovieOverview={getMovieOverview}
                            />
                        )}
                    </Grid>
                ))}
            </Grid>
        </>
    );
};
export default MovieOverview;
