import React, { FunctionComponent } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Card, CardMedia, CardActions, IconButton, CardContent, Typography, Link } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { MovieViewCardModelProps } from "../../models";
import { DateTimeHelper } from "../../helpers";

const useStyles = makeStyles(() =>
    createStyles({
        iconRow: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "8px 0 0"
        },
        iconImage: {
            width: "20%"
        },
        done: {
            color: "green !important"
        }
    })
);

const MovieViewCard: FunctionComponent<MovieViewCardModelProps> = ({ userId, movie, setEditMode, updateMovie, getMovieOverview }) => {
    const classes = useStyles();

    const toggleDone = async () => {
        const newMovie = {
            ...movie,
            doneDate: new Date().toISOString().split("T")[0],
            isDone: !movie.isDone
        };
        await updateMovie(userId, newMovie, movie.id ?? "");
        getMovieOverview(userId);
    };

    return (
        <Card
            variant="outlined"
            sx={{ paddingBottom: "4px", width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            {movie.poster !== "" && (
                <CardMedia
                    sx={{
                        height: {
                            xs: "100%",
                            md: "380px"
                        }
                    }}
                    component="img"
                    image={movie.poster}
                    alt="activity image"
                />
            )}
            <CardContent sx={{ padding: "16px 16px 0" }}>
                <Typography
                    variant="h6"
                    sx={{ lineHeight: "1.2" }}
                    color="text.secondary">
                    {movie.name}
                </Typography>
                {movie.duration && (
                    <Typography
                        variant="body2"
                        color="text.secondary">
                        {movie.duration} Minuten
                    </Typography>
                )}
                <Typography
                    variant="body2"
                    sx={{ marginBottom: "8px" }}
                    color="text.secondary">
                    {DateTimeHelper.toDateDisplay(movie.releaseDate)}
                </Typography>
                {movie.isDone && movie.doneDate && (
                    <Typography
                        variant="body2"
                        sx={{ marginBottom: "8px" }}
                        color="text.secondary">
                        Gesehen: {DateTimeHelper.toDateDisplay(movie.doneDate)}
                    </Typography>
                )}
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", lineHeight: "1" }}
                    color="text.secondary">
                    {movie.genre}
                </Typography>
                {movie.linkKino && (
                    <Link
                        href={movie.linkKino}
                        target="_blank"
                        variant="body2">
                        {"Kino.de"}
                    </Link>
                )}
                <br />
                {movie.linkStream && (
                    <Link
                        href={movie.linkStream}
                        target="_blank"
                        variant="body2">
                        {"werstreamt.es"}
                    </Link>
                )}
                {(movie.isAmazon || movie.isDisney || movie.isNetflix || movie.isSky) && (
                    <div className={classes.iconRow}>
                        {movie.isNetflix && (
                            <img
                                className={classes.iconImage}
                                src="../assets/Netflix.png"></img>
                        )}
                        {movie.isSky && (
                            <img
                                className={classes.iconImage}
                                src="../assets/Sky.webp"></img>
                        )}
                        {movie.isAmazon && (
                            <img
                                className={classes.iconImage}
                                src="../assets/PrimeVideo.webp"></img>
                        )}
                        {movie.isDisney && (
                            <img
                                className={classes.iconImage}
                                src="../assets/disney.png"></img>
                        )}
                    </div>
                )}
            </CardContent>
            <CardActions
                sx={{ padding: "0 8px 8px" }}
                disableSpacing>
                <IconButton
                    onClick={() => setEditMode(movie.id ?? "", true)}
                    aria-label="edit">
                    <EditIcon />
                </IconButton>
                <IconButton
                    className={movie.isDone ? classes.done : ""}
                    onClick={toggleDone}
                    aria-label="done">
                    <CheckIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
};

export default MovieViewCard;
