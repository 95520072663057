import React, { FunctionComponent } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Card, CardMedia, CardActions, IconButton, CardContent, Typography, Link } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { GameViewCardModelProps } from "../../models";
import { DateTimeHelper } from "../../helpers";

const useStyles = makeStyles(() =>
    createStyles({
        iconRow: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "16px 0"
        },
        iconImage: {
            width: "18%"
        },
        iconImageGrey: {
            width: "18%",
            cursor: "pointer",
            filter: "grayscale(1)"
        },
        done: {
            color: "green !important"
        }
    })
);

const GameViewCard: FunctionComponent<GameViewCardModelProps> = ({ userId, game, setEditMode, updateGame, getGameOverview }) => {
    const classes = useStyles();

    const toggleDone = async () => {
        const newGame = {
            ...game,
            doneDate: new Date().toISOString().split("T")[0],
            isDone: !game.isDone
        };
        await updateGame(userId, newGame, game.id ?? "");
        getGameOverview(userId);
    };

    const toggleStarted = async () => {
        const newGame = {
            ...game,
            startDate: new Date().toISOString().split("T")[0],
            hasStarted: !game.hasStarted
        };
        await updateGame(userId, newGame, game.id ?? "");
        getGameOverview(userId);
    };

    return (
        <Card
            variant="outlined"
            sx={{ paddingBottom: "4px", width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            {game.poster !== "" && (
                <CardMedia
                    sx={{
                        height: {
                            xs: "100%",
                            md: "380px"
                        }
                    }}
                    component="img"
                    image={game.poster}
                    alt="activity image"
                />
            )}
            <CardContent sx={{ padding: "16px 16px 0" }}>
                <Typography
                    variant="h6"
                    sx={{ lineHeight: "1.2" }}
                    color="text.secondary">
                    {game.name}
                </Typography>
                {game.duration && (
                    <Typography
                        variant="body2"
                        color="text.secondary">
                        {game.duration} Stunden
                    </Typography>
                )}
                {game.releaseDate && (
                    <Typography
                        variant="body2"
                        sx={{ marginBottom: "8px" }}
                        color="text.secondary">
                        {DateTimeHelper.toDateDisplay(game.releaseDate)}
                    </Typography>
                )}
                {game.hasStarted && !game.isDone && game.startDate && (
                    <Typography
                        variant="body2"
                        sx={{ marginBottom: "8px" }}
                        color="text.secondary">
                        Gestartet: {DateTimeHelper.toDateDisplay(game.startDate)}
                    </Typography>
                )}
                {game.isDone && game.doneDate && game.startDate && (
                    <Typography
                        variant="body2"
                        color="text.secondary">
                        Spielzeit: {DateTimeHelper.dateDiff(game.doneDate, game.startDate)}
                    </Typography>
                )}
                {game.isDone && game.doneDate && (
                    <Typography
                        variant="body2"
                        sx={{ marginBottom: "8px" }}
                        color="text.secondary">
                        Beendet: {DateTimeHelper.toDateDisplay(game.doneDate)}
                    </Typography>
                )}
                <Typography
                    variant="body2"
                    sx={{ marginBottom: "8px" }}
                    color="text.secondary"></Typography>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", lineHeight: "1" }}
                    color="text.secondary">
                    {game.genre}
                </Typography>
                {game.linkIGDB && (
                    <Link
                        href={game.linkIGDB}
                        target="_blank"
                        variant="body2">
                        {"IGDB.com"}
                    </Link>
                )}
                {(game.isGamePass || game.isOne || game.isPC || game.isSeries || game.isSwitch) && (
                    <div className={classes.iconRow}>
                        {game.isGamePass && (
                            <img
                                className={classes.iconImage}
                                src="../assets/GamePass.png"></img>
                        )}
                        {game.isPC && (
                            <img
                                className={classes.iconImage}
                                src="../assets/PC.png"></img>
                        )}
                        {game.isOne && (
                            <img
                                className={classes.iconImage}
                                src="../assets/one.jpg"></img>
                        )}
                        {game.isSeries && (
                            <img
                                className={classes.iconImage}
                                src="../assets/series.png"></img>
                        )}
                        {game.isSwitch && (
                            <img
                                className={classes.iconImage}
                                src="../assets/switch.png"></img>
                        )}
                    </div>
                )}
            </CardContent>
            <CardActions
                sx={{ padding: "0 8px 8px" }}
                disableSpacing>
                <IconButton
                    onClick={() => setEditMode(game.id ?? "", true)}
                    aria-label="edit">
                    <EditIcon />
                </IconButton>
                <IconButton
                    className={game.isDone ? classes.done : ""}
                    onClick={toggleDone}
                    aria-label="done">
                    <CheckIcon />
                </IconButton>
                <IconButton
                    className={game.hasStarted ? classes.done : ""}
                    onClick={toggleStarted}
                    aria-label="start">
                    <PlayCircleIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
};

export default GameViewCard;
