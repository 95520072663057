import React, { FunctionComponent, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { GameEditCard, GameViewCard } from ".";
import { Game, GameOverviewProps } from "../models";

/**
 * Answer Question
 */
const GameOverview: FunctionComponent<GameOverviewProps> = ({ gameOverview, getGameOverview, saveGame, updateGame, deleteGame, userId }) => {
    const [games, setGames] = useState(gameOverview);

    useEffect(() => {
        setGames(gameOverview);
    }, [gameOverview]);

    const setEditMode = (id: string, mode: boolean) => {
        setGames((preValue) => {
            if (id === "") {
                getGameOverview(userId);
            }
            return preValue.map((game) => {
                return {
                    ...game,
                    isEdit: game.id === id ? mode : game.isEdit
                };
            });
        });
    };

    return (
        <>
            <Grid
                container
                spacing={2}>
                {games.map((game: Game, index: number) => (
                    <Grid
                        item
                        key={index}
                        xs={6}
                        md={4}>
                        {game.isEdit && (
                            <GameEditCard
                                getGameOverview={getGameOverview}
                                saveGame={saveGame}
                                updateGame={updateGame}
                                setEditMode={setEditMode}
                                deleteGame={deleteGame}
                                game={game}
                                userId={userId}
                            />
                        )}
                        {!game.isEdit && (
                            <GameViewCard
                                game={game}
                                userId={userId}
                                setEditMode={setEditMode}
                                updateGame={updateGame}
                                getGameOverview={getGameOverview}
                            />
                        )}
                    </Grid>
                ))}
            </Grid>
        </>
    );
};
export default GameOverview;
