import ApiService from "./ApiService";
import { Movie } from "../models";

/**
 * Assessment Service
 * This service is responsible for save the assessment
 */
const MovieService = {
    async getMovieOverview(uid: string): Promise<Movie[]> {
        return ApiService.getData(`${uid}/movie`);
    },

    async deleteMovie(uid: string, id: string): Promise<boolean | void> {
        if (id && id !== "") {
            return ApiService.deleteData(`${uid}/movie/${id}`);
        }
    },

    async postMovie(uid: string, movie: Movie): Promise<boolean | void> {
        if (movie.releaseDate === "") {
            movie.releaseDate = new Date().toISOString().split("T")[0];
        }
        return ApiService.postData(`${uid}/movie`, movie);
    },

    async updateMovie(uid: string, movie: Movie, movieId: string): Promise<boolean | void> {
        return ApiService.updateData(`${uid}/movie/${movieId}`, movie);
    },

    async getMovieById(uid: string, id: string): Promise<any> {
        return ApiService.getData(`${uid}/movie/${id}`);
    }
};

export default MovieService;
