import React, { FunctionComponent } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Card, CardMedia, CardActions, IconButton, CardContent, Typography, Link } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { SerieViewCardModelProps } from "../../models";
import { DateTimeHelper } from "../../helpers";

const useStyles = makeStyles(() =>
    createStyles({
        iconRow: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "16px 0"
        },
        iconImage: {
            width: "18%"
        },
        iconImageGrey: {
            width: "18%",
            cursor: "pointer",
            filter: "grayscale(1)"
        },
        done: {
            color: "green !important"
        }
    })
);

const SerieViewCard: FunctionComponent<SerieViewCardModelProps> = ({ userId, serie, setEditMode, updateSerie, getSerieOverview }) => {
    const classes = useStyles();

    const toggleDone = async () => {
        const newSerie = {
            ...serie,
            doneDate: new Date().toISOString().split("T")[0],
            isDone: !serie.isDone
        };
        await updateSerie(userId, newSerie, serie.id ?? "");
        getSerieOverview(userId);
    };

    const toggleStarted = async () => {
        const newSerie = {
            ...serie,
            hasStarted: !serie.hasStarted
        };
        await updateSerie(userId, newSerie, serie.id ?? "");
        getSerieOverview(userId);
    };

    return (
        <Card
            variant="outlined"
            sx={{ paddingBottom: "4px", width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            {serie.poster !== "" && (
                <CardMedia
                    sx={{
                        height: {
                            xs: "100%",
                            md: "380px"
                        }
                    }}
                    component="img"
                    image={serie.poster}
                    alt="activity image"
                />
            )}
            <CardContent sx={{ padding: "16px 16px 0" }}>
                <Typography
                    variant="h6"
                    sx={{ lineHeight: "1.2" }}
                    color="text.secondary">
                    {serie.name}
                </Typography>
                {serie.duration && (
                    <Typography
                        variant="body2"
                        color="text.secondary">
                        {serie.duration} Minuten
                    </Typography>
                )}
                {serie.releaseDate && (
                    <Typography
                        variant="body2"
                        sx={{ marginBottom: "8px" }}
                        color="text.secondary">
                        {DateTimeHelper.toDateDisplay(serie.releaseDate)}
                    </Typography>
                )}
                {serie.isDone && serie.doneDate && (
                    <Typography
                        variant="body2"
                        sx={{ marginBottom: "8px" }}
                        color="text.secondary">
                        Gesehen: {DateTimeHelper.toDateDisplay(serie.doneDate)}
                    </Typography>
                )}
                <Typography
                    variant="body2"
                    sx={{ marginBottom: "8px" }}
                    color="text.secondary"></Typography>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", lineHeight: "1" }}
                    color="text.secondary">
                    {serie.genre}
                </Typography>
                {serie.linkKino && (
                    <Link
                        href={serie.linkKino}
                        target="_blank"
                        variant="body2">
                        {"Kino.de"}
                    </Link>
                )}
                <br />
                {serie.linkStream && (
                    <Link
                        href={serie.linkStream}
                        target="_blank"
                        variant="body2">
                        {"werstreamt.es"}
                    </Link>
                )}
                {(serie.isAmazon || serie.isDisney || serie.isNetflix || serie.isSky) && (
                    <div className={classes.iconRow}>
                        {serie.isNetflix && (
                            <img
                                className={classes.iconImage}
                                src="../assets/Netflix.png"></img>
                        )}
                        {serie.isSky && (
                            <img
                                className={classes.iconImage}
                                src="../assets/Sky.webp"></img>
                        )}
                        {serie.isAmazon && (
                            <img
                                className={classes.iconImage}
                                src="../assets/PrimeVideo.webp"></img>
                        )}
                        {serie.isDisney && (
                            <img
                                className={classes.iconImage}
                                src="../assets/disney.png"></img>
                        )}
                    </div>
                )}
            </CardContent>
            <CardActions
                sx={{ padding: "0 8px 8px" }}
                disableSpacing>
                <IconButton
                    onClick={() => setEditMode(serie.id ?? "", true)}
                    aria-label="edit">
                    <EditIcon />
                </IconButton>
                <IconButton
                    className={serie.isDone ? classes.done : ""}
                    onClick={toggleDone}
                    aria-label="done">
                    <CheckIcon />
                </IconButton>
                <IconButton
                    className={serie.hasStarted ? classes.done : ""}
                    onClick={toggleStarted}
                    aria-label="start">
                    <PlayCircleIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
};

export default SerieViewCard;
