import React, { FunctionComponent, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Card, TextField, CardActions, IconButton, CardContent } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { GameEditCardModelProps, Game } from "../../models";

const useStyles = makeStyles(() =>
    createStyles({
        iconRow: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "16px 0"
        },
        iconImage: {
            width: "18%",
            cursor: "pointer"
        },
        iconImageGrey: {
            width: "18%",
            cursor: "pointer",
            filter: "grayscale(1)"
        }
    })
);

const GameEditCard: FunctionComponent<GameEditCardModelProps> = ({ userId, game, updateGame, saveGame, setEditMode, getGameOverview, deleteGame }) => {
    const classes = useStyles();
    const [name, setName] = useState(game?.name ?? "");
    const [releaseDate, setReleaseDate] = useState(game?.releaseDate ?? "");
    const [poster, setPoster] = useState(game?.poster ?? "");
    const [genre, setGenre] = useState(game?.genre ?? "");
    const [linkIGDB, setLinkIGDB] = useState(game?.linkIGDB ?? "");
    const [isGamePass, setIsGamePass] = useState(game?.isGamePass ?? false);
    const [isOne, setIsOne] = useState(game?.isOne ?? false);
    const [isPC, setIsPC] = useState(game?.isPC ?? false);
    const [isSeries, setIsSeries] = useState(game?.isSeries ?? false);
    const [isSwitch, setIsSwitch] = useState(game?.isSwitch ?? false);
    const [duration, setDuration] = useState(game?.duration ?? "");

    const inputSx = {
        input: { color: "#3e3e3e" },
        "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
                borderColor: "#32746d"
            }
        }
    };

    const toggleGamePass = () => {
        setIsGamePass(!isGamePass);
    };

    const toggleOne = () => {
        setIsOne(!isOne);
    };

    const togglePC = () => {
        setIsPC(!isPC);
    };

    const toggleSeries = () => {
        setIsSeries(!isSeries);
    };

    const toggleSwitch = () => {
        setIsSwitch(!isSwitch);
    };

    const saveForm = async () => {
        const newGame: Game = {
            name: name,
            poster: poster,
            releaseDate: releaseDate,
            isGamePass: isGamePass,
            isOne: isOne,
            isPC: isPC,
            isSeries: isSeries,
            isSwitch: isSwitch,
            genre: genre,
            linkIGDB: linkIGDB,
            duration: duration,
            isEdit: false,
            isDone: game.isDone,
            hasStarted: game.hasStarted
        };
        if (game.doneDate) newGame.doneDate = game.doneDate;
        if (game.startDate) newGame.startDate = game.startDate;
        if (game.id) {
            await updateGame(userId, newGame, game.id ?? "0");
        } else {
            await saveGame(userId, newGame);
        }
        await getGameOverview(userId);
        setEditMode(game.id ?? "", false);
    };

    const clickRemove = async () => {
        await deleteGame(userId, game.id ?? "");
        setEditMode(game.id ?? "", false);
        await getGameOverview(userId);
    };

    return (
        <Card
            variant="outlined"
            sx={{ paddingBottom: "8px", width: "100%" }}>
            <CardContent sx={{ paddingBottom: "8px" }}>
                <TextField
                    margin="dense"
                    label="Name"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Poster URL"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={poster}
                    onChange={(e) => setPoster(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Datum"
                    type="date"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={releaseDate}
                    onChange={(e) => setReleaseDate(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Genre"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={genre}
                    onChange={(e) => setGenre(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="IGDB Link"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={linkIGDB}
                    onChange={(e) => setLinkIGDB(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Dauer (in h)"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                />
                <div className={classes.iconRow}>
                    <img
                        className={isGamePass ? classes.iconImage : classes.iconImageGrey}
                        onClick={toggleGamePass}
                        src="../assets/GamePass.png"></img>
                    <img
                        className={isPC ? classes.iconImage : classes.iconImageGrey}
                        onClick={togglePC}
                        src="../assets/PC.png"></img>
                    <img
                        className={isOne ? classes.iconImage : classes.iconImageGrey}
                        onClick={toggleOne}
                        src="../assets/one.jpg"></img>
                    <img
                        className={isSeries ? classes.iconImage : classes.iconImageGrey}
                        onClick={toggleSeries}
                        src="../assets/series.png"></img>
                    <img
                        className={isSwitch ? classes.iconImage : classes.iconImageGrey}
                        onClick={toggleSwitch}
                        src="../assets/switch.png"></img>
                </div>
            </CardContent>
            <CardActions
                sx={{ paddingTop: 0 }}
                disableSpacing>
                <IconButton
                    onClick={saveForm}
                    aria-label="edit">
                    <SaveIcon />
                </IconButton>
                <IconButton
                    onClick={() => setEditMode(game.id ?? "", false)}
                    aria-label="close">
                    <CloseIcon />
                </IconButton>
                {game.id && (
                    <IconButton
                        onClick={clickRemove}
                        aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                )}
            </CardActions>
        </Card>
    );
};

export default GameEditCard;
