import ApiService from "./ApiService";
import { Book } from "../models";

const BookService = {
    async getBookOverview(uid: string): Promise<Book[]> {
        return ApiService.getData(`${uid}/book`);
    },

    async deleteBook(uid: string, id: string): Promise<boolean | void> {
        if (id && id !== "") {
            return ApiService.deleteData(`${uid}/book/${id}`);
        }
    },

    async postBook(uid: string, book: Book): Promise<boolean | void> {
        return ApiService.postData(`${uid}/book`, book);
    },

    async updateBook(uid: string, book: Book, bookId: string): Promise<boolean | void> {
        return ApiService.updateData(`${uid}/book/${bookId}`, book);
    },

    async getBookById(uid: string, id: string): Promise<any> {
        return ApiService.getData(`${uid}/book/${id}`);
    }
};

export default BookService;
