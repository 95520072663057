import create from "zustand";
import { Serie } from "../models";
import SerieService from "../services/SerieService";

interface State {
    serieOverview: Serie[] | null;
    loadingSeries: boolean;
    loadingSerie: boolean;
    savingSerie: boolean;
    createNewSerie: () => void;
    getSerieOverview: (uid: string) => Promise<any>;
    saveSerie: (uid: string, serie: Serie) => Promise<any>;
    updateSerie: (uid: string, serie: Serie, serieId: string) => Promise<any>;
    resetSerieOverview: () => void;
    deleteSerie: (uid: string, serieId: string) => Promise<any>;
}

const initSerie: Serie = {
    name: "",
    isAmazon: false,
    isNetflix: false,
    isDisney: false,
    isSky: false,
    hasStarted: false,
    isEdit: true,
    isDone: false
};

const useStore = create<State>((set) => ({
    serieOverview: null,
    loadingSeries: false,
    loadingSerie: false,
    savingSerie: false,
    createNewSerie: () => set((state) => ({ serieOverview: [initSerie, ...(state.serieOverview ? state.serieOverview : [])] })),
    getSerieOverview: async (uid: string): Promise<any> => {
        let series = await SerieService.getSerieOverview(uid);
        if (series) {
            series = Object.entries(series).map((serie) => {
                return { ...serie[1], id: serie[0] };
            });
        } else {
            series = [];
        }
        set(() => ({ serieOverview: series }));
    },
    saveSerie: (uid: string, serie: Serie) => SerieService.postSerie(uid, serie),
    updateSerie: (uid: string, serie: Serie, serieId: string) => SerieService.updateSerie(uid, serie, serieId),
    resetSerieOverview: () => set({ serieOverview: null }),
    deleteSerie: (uid: string, serieId: string) => SerieService.deleteSerie(uid, serieId)
}));

export const SerieStore = useStore;
