import React, { FunctionComponent, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { BookEditCard, BookViewCard } from ".";
import { Book, BookOverviewProps } from "../models";

/**
 * Answer Question
 */
const BookOverview: FunctionComponent<BookOverviewProps> = ({ bookOverview, getBookOverview, saveBook, updateBook, deleteBook, userId }) => {
    const [books, setBooks] = useState(bookOverview);

    useEffect(() => {
        setBooks(bookOverview);
    }, [bookOverview]);

    const setEditMode = (id: string, mode: boolean) => {
        setBooks((preValue) => {
            if (id === "") {
                getBookOverview(userId);
            }
            return preValue.map((book) => {
                return {
                    ...book,
                    isEdit: book.id === id ? mode : book.isEdit
                };
            });
        });
    };

    return (
        <>
            <Grid
                container
                spacing={2}>
                {books.map((book: Book, index: number) => (
                    <Grid
                        item
                        key={index}
                        xs={6}
                        md={4}>
                        {book.isEdit && (
                            <BookEditCard
                                getBookOverview={getBookOverview}
                                saveBook={saveBook}
                                updateBook={updateBook}
                                setEditMode={setEditMode}
                                deleteBook={deleteBook}
                                book={book}
                                userId={userId}
                            />
                        )}
                        {!book.isEdit && (
                            <BookViewCard
                                book={book}
                                userId={userId}
                                setEditMode={setEditMode}
                                updateBook={updateBook}
                                getBookOverview={getBookOverview}
                            />
                        )}
                    </Grid>
                ))}
            </Grid>
        </>
    );
};
export default BookOverview;
