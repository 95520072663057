import ApiService from "./ApiService";
import { Game } from "../models";

const GameService = {
    async getGameOverview(uid: string): Promise<Game[]> {
        return ApiService.getData(`${uid}/game`);
    },

    async deleteGame(uid: string, id: string): Promise<boolean | void> {
        if (id && id !== "") {
            return ApiService.deleteData(`${uid}/game/${id}`);
        }
    },

    async postGame(uid: string, game: Game): Promise<boolean | void> {
        return ApiService.postData(`${uid}/game`, game);
    },

    async updateGame(uid: string, game: Game, gameId: string): Promise<boolean | void> {
        return ApiService.updateData(`${uid}/game/${gameId}`, game);
    },

    async getGameById(uid: string, id: string): Promise<any> {
        return ApiService.getData(`${uid}/game/${id}`);
    }
};

export default GameService;
