import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Button, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Game } from "../models";
import { PageContainer } from "../hoc";
import { GameStore, UserStore } from "../stores";
import { GameOverview, CategoryHeader } from "../components";
import { DateTimeHelper } from "../helpers";

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            "margin-top": "20px"
        }
    })
);

const GameOverviewContainer: FunctionComponent = () => {
    const classes = useStyles();
    const gameOverview = GameStore((state) => state.gameOverview);
    const getGameOverview = GameStore((state) => state.getGameOverview);
    const resetGameOverview = GameStore((state) => state.resetGameOverview);
    const createNewGame = GameStore((state) => state.createNewGame);
    const saveGame = GameStore((state) => state.saveGame);
    const updateGame = GameStore((state) => state.updateGame);
    const deleteGame = GameStore((state) => state.deleteGame);
    const userState = UserStore((state) => state.user);
    const [startedCollapsed, setStartedCollapsed] = useState(false);
    const [gamePassCollapsed, setGamePassCollapsed] = useState(true);
    const [availableCollapsed, setAvailableCollapsed] = useState(true);
    const [openCollapsed, setOpenCollapsed] = useState(true);
    const [doneCollapsed, setDoneCollapsed] = useState(true);
    const [filterStarted, setFilterStarted] = useState<Game[]>([]);
    const [filterGamePass, setFilterGamePass] = useState<Game[]>([]);
    const [filterAvailable, setFilterAvailable] = useState<Game[]>([]);
    const [filterOpen, setFilterOpen] = useState<Game[]>([]);
    const [filterDone, setFilterDone] = useState<Game[]>([]);
    const [filterNew, setFilterNew] = useState<Game[]>([]);
    const openRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (gameOverview === null && userState) {
            getGameOverview(userState.uid);
        } else {
            setFilterStarted(
                gameOverview
                    ?.filter((game) => !game.isDone && game.hasStarted)
                    .sort((gameA: Game, gameB: Game) => DateTimeHelper.compareDates(gameA.startDate, gameB.startDate, gameA.releaseDate, gameB.releaseDate)) ??
                    []
            );
            setFilterGamePass(
                gameOverview
                    ?.filter((game) => !game.isDone && !game.hasStarted && game.isGamePass)
                    .sort((gameA: Game, gameB: Game) => DateTimeHelper.compareDates(gameA.releaseDate, gameB.releaseDate)) ?? []
            );
            setFilterAvailable(
                gameOverview
                    ?.filter(
                        (game) =>
                            (game.isPC || game.isOne || game.isSeries || game.isSwitch) &&
                            !(game.isDone || game.hasStarted || game.isGamePass) &&
                            isReleased(game)
                    )
                    .sort((gameA: Game, gameB: Game) => DateTimeHelper.compareDates(gameA.releaseDate, gameB.releaseDate)) ?? []
            );
            setFilterOpen(
                gameOverview
                    ?.filter((game) => (!(game.isOne || game.isPC || game.isSeries || game.isSwitch) && !game.isDone && game.id) || !isReleased(game))
                    .sort((gameA: Game, gameB: Game) => DateTimeHelper.compareDates(gameA.releaseDate, gameB.releaseDate)) ?? []
            );
            setFilterDone(
                gameOverview
                    ?.filter((game) => game.isDone)
                    .sort((gameA: Game, gameB: Game) =>
                        DateTimeHelper.compareDates(gameA.doneDate, gameB.doneDate, gameA.releaseDate, gameB.releaseDate, false)
                    ) ?? []
            );
            setFilterNew(gameOverview?.filter((game) => !game.id) ?? []);
        }
    }, [gameOverview, userState, getGameOverview]);

    useEffect(() => {
        return () => {
            resetGameOverview();
        };
    }, [resetGameOverview]);

    const isReleased = (game: Game) => {
        const today = new Date();
        if (!game.releaseDate) {
            return false;
        }
        const gameDate = new Date(game.releaseDate);
        return today > gameDate;
    };

    const createNewGameAction = () => {
        if (filterNew.length === 0) {
            createNewGame();
        }
    };

    return (
        <PageContainer active={2}>
            <Typography
                variant="h3"
                mt={2}
                mb={2}
                color="text.primary">
                Spiele
            </Typography>
            {(!gameOverview || gameOverview.length === 0) && (
                <Typography
                    variant="h4"
                    mt={2}
                    mb={2}
                    color="text.primary">
                    {!gameOverview ? "Spiele werden geladen..." : "Keine Spiele gefunden"}
                </Typography>
            )}
            <Button
                size="small"
                variant="outlined"
                sx={{ marginTop: "16px", marginBottom: "16px" }}
                className={classes.button}
                onClick={createNewGameAction}>
                Spiel hinzufügen
            </Button>
            {gameOverview && filterNew.length > 0 && (
                <GameOverview
                    gameOverview={filterNew}
                    userId={userState?.uid ? userState?.uid : ""}
                    saveGame={saveGame}
                    updateGame={updateGame}
                    getGameOverview={getGameOverview}
                    deleteGame={deleteGame}
                />
            )}
            {gameOverview && filterStarted.length > 0 && (
                <>
                    <CategoryHeader
                        setCollapsed={setStartedCollapsed}
                        collapsed={startedCollapsed}
                        length={filterStarted.length}
                        title="Gestartet"
                    />
                    {!startedCollapsed && (
                        <GameOverview
                            gameOverview={filterStarted}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveGame={saveGame}
                            updateGame={updateGame}
                            getGameOverview={getGameOverview}
                            deleteGame={deleteGame}
                        />
                    )}
                </>
            )}
            {gameOverview && filterGamePass.length > 0 && (
                <>
                    <CategoryHeader
                        setCollapsed={setGamePassCollapsed}
                        collapsed={gamePassCollapsed}
                        length={filterGamePass.length}
                        title="Verfügbar im GamePass"
                    />
                    {!gamePassCollapsed && (
                        <GameOverview
                            gameOverview={filterGamePass}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveGame={saveGame}
                            updateGame={updateGame}
                            getGameOverview={getGameOverview}
                            deleteGame={deleteGame}
                        />
                    )}
                </>
            )}
            {gameOverview && filterAvailable.length > 0 && (
                <>
                    <CategoryHeader
                        setCollapsed={setAvailableCollapsed}
                        collapsed={availableCollapsed}
                        length={filterAvailable.length}
                        title="Verfügbar"
                    />
                    {!availableCollapsed && (
                        <GameOverview
                            gameOverview={filterAvailable}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveGame={saveGame}
                            updateGame={updateGame}
                            getGameOverview={getGameOverview}
                            deleteGame={deleteGame}
                        />
                    )}
                </>
            )}
            {gameOverview && filterOpen.length && (
                <div ref={openRef}>
                    <CategoryHeader
                        setCollapsed={setOpenCollapsed}
                        collapsed={openCollapsed}
                        length={filterOpen.length}
                        title="Offen"
                    />
                    {!openCollapsed && (
                        <GameOverview
                            gameOverview={filterOpen}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveGame={saveGame}
                            updateGame={updateGame}
                            getGameOverview={getGameOverview}
                            deleteGame={deleteGame}
                        />
                    )}
                </div>
            )}
            {gameOverview && filterDone.length > 0 && (
                <>
                    <CategoryHeader
                        setCollapsed={setDoneCollapsed}
                        collapsed={doneCollapsed}
                        length={filterDone.length}
                        title="Gespielt"
                    />
                    {!doneCollapsed && (
                        <GameOverview
                            gameOverview={filterDone}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveGame={saveGame}
                            updateGame={updateGame}
                            getGameOverview={getGameOverview}
                            deleteGame={deleteGame}
                        />
                    )}
                </>
            )}
        </PageContainer>
    );
};

export default GameOverviewContainer;
