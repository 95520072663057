import React, { FunctionComponent, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Card, TextField, CardActions, IconButton, CardContent } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { BookEditCardModelProps, Book } from "../../models";

const useStyles = makeStyles(() =>
    createStyles({
        iconRow: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "16px 0"
        },
        iconImage: {
            width: "18%",
            cursor: "pointer"
        },
        iconImageGrey: {
            width: "18%",
            cursor: "pointer",
            filter: "grayscale(1)"
        }
    })
);

const BookEditCard: FunctionComponent<BookEditCardModelProps> = ({ userId, book, updateBook, saveBook, setEditMode, getBookOverview, deleteBook }) => {
    const classes = useStyles();
    const [name, setName] = useState(book?.name ?? "");
    const [releaseDate, setReleaseDate] = useState(book?.releaseDate ?? "");
    const [poster, setPoster] = useState(book?.poster ?? "");
    const [genre, setGenre] = useState(book?.genre ?? "");
    const [linkGoodreads, setLinkGoodreads] = useState(book?.linkGoodreads ?? "");
    const [isOnleihe, setIsOnleihe] = useState(book?.isOnleihe ?? false);
    const [isLibrary, setIsLibrary] = useState(book?.isLibrary ?? false);
    const [duration, setDuration] = useState(book?.duration ?? "");

    const inputSx = {
        input: { color: "#3e3e3e" },
        "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
                borderColor: "#32746d"
            }
        }
    };

    const toggleOnleihe = () => {
        setIsOnleihe(!isOnleihe);
    };

    const toggleLibrary = () => {
        setIsLibrary(!isLibrary);
    };

    const saveForm = async () => {
        const newBook: Book = {
            name: name,
            poster: poster,
            releaseDate: releaseDate,
            isOnleihe: isOnleihe,
            isLibrary: isLibrary,
            genre: genre,
            linkGoodreads: linkGoodreads,
            duration: duration,
            isEdit: false,
            isDone: book.isDone,
            hasStarted: book.hasStarted
        };
        if (book.doneDate) newBook.doneDate = book.doneDate;
        if (book.startDate) newBook.startDate = book.startDate;
        if (book.id) {
            await updateBook(userId, newBook, book.id ?? "0");
        } else {
            await saveBook(userId, newBook);
        }
        await getBookOverview(userId);
        setEditMode(book.id ?? "", false);
    };

    const clickRemove = async () => {
        await deleteBook(userId, book.id ?? "");
        setEditMode(book.id ?? "", false);
        await getBookOverview(userId);
    };

    return (
        <Card
            variant="outlined"
            sx={{ paddingBottom: "8px", width: "100%" }}>
            <CardContent sx={{ paddingBottom: "8px" }}>
                <TextField
                    margin="dense"
                    label="Name"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Cover URL"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={poster}
                    onChange={(e) => setPoster(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Datum"
                    type="date"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={releaseDate}
                    onChange={(e) => setReleaseDate(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Genre"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={genre}
                    onChange={(e) => setGenre(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="goodreads Link"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={linkGoodreads}
                    onChange={(e) => setLinkGoodreads(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Seiten"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                />
                <div className={classes.iconRow}>
                    <img
                        className={isLibrary ? classes.iconImage : classes.iconImageGrey}
                        onClick={toggleLibrary}
                        src="../assets/library.png"></img>
                    <img
                        className={isOnleihe ? classes.iconImage : classes.iconImageGrey}
                        onClick={toggleOnleihe}
                        src="../assets/onleihe.png"></img>
                </div>
            </CardContent>
            <CardActions
                sx={{ paddingTop: 0 }}
                disableSpacing>
                <IconButton
                    onClick={saveForm}
                    aria-label="edit">
                    <SaveIcon />
                </IconButton>
                <IconButton
                    onClick={() => setEditMode(book.id ?? "", false)}
                    aria-label="close">
                    <CloseIcon />
                </IconButton>
                {book.id && (
                    <IconButton
                        onClick={clickRemove}
                        aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                )}
            </CardActions>
        </Card>
    );
};

export default BookEditCard;
