import React, { FunctionComponent, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Card, TextField, CardActions, IconButton, CardContent } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { MovieEditCardModelProps, Movie } from "../../models";

const useStyles = makeStyles(() =>
    createStyles({
        iconRow: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "16px 0"
        },
        iconImage: {
            width: "20%",
            cursor: "pointer"
        },
        iconImageGrey: {
            width: "20%",
            cursor: "pointer",
            filter: "grayscale(1)"
        }
    })
);

const MovieEditCard: FunctionComponent<MovieEditCardModelProps> = ({ userId, movie, updateMovie, saveMovie, setEditMode, getMovieOverview, deleteMovie }) => {
    const classes = useStyles();
    const [name, setName] = useState(movie?.name ?? "");
    const [releaseDate, setReleaseDate] = useState(movie?.releaseDate ?? "");
    const [poster, setPoster] = useState(movie?.poster ?? "");
    const [genre, setGenre] = useState(movie?.genre ?? "");
    const [linkKino, setLinkKino] = useState(movie?.linkKino ?? "");
    const [linkStream, setLinkStream] = useState(movie?.linkStream ?? "");
    const [isAmazon, setIsAmazon] = useState(movie?.isAmazon ?? false);
    const [isDisney, setIsDisney] = useState(movie?.isDisney ?? false);
    const [isNetflix, setIsNetflix] = useState(movie?.isNetflix ?? false);
    const [isSky, setIsSky] = useState(movie?.isSky ?? false);
    const [duration, setDuration] = useState(movie?.duration ?? "");

    const inputSx = {
        input: { color: "#3e3e3e" },
        "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
                borderColor: "#32746d"
            }
        }
    };

    const toggleNetflix = () => {
        setIsNetflix(!isNetflix);
    };

    const toggleDisney = () => {
        setIsDisney(!isDisney);
    };

    const toggleAmazon = () => {
        setIsAmazon(!isAmazon);
    };

    const toggleSky = () => {
        setIsSky(!isSky);
    };

    const saveForm = async () => {
        const newMovie: Movie = {
            name: name,
            poster: poster,
            releaseDate: releaseDate,
            isAmazon: isAmazon,
            isNetflix: isNetflix,
            isDisney: isDisney,
            isSky: isSky,
            genre: genre,
            linkKino: linkKino,
            linkStream: linkStream,
            isEdit: false,
            isDone: movie.isDone,
            duration: duration
        };
        if (movie.doneDate) newMovie.doneDate = movie.doneDate;
        if (movie.id) {
            await updateMovie(userId, newMovie, movie.id ?? "0");
        } else {
            await saveMovie(userId, newMovie);
        }
        await getMovieOverview(userId);
        setEditMode(movie.id ?? "", false);
    };

    const clickRemove = async () => {
        await deleteMovie(userId, movie.id ?? "");
        setEditMode(movie.id ?? "", false);
        await getMovieOverview(userId);
    };

    return (
        <Card
            variant="outlined"
            sx={{ paddingBottom: "8px", width: "100%" }}>
            <CardContent sx={{ paddingBottom: "8px" }}>
                <TextField
                    margin="dense"
                    label="Name"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Poster URL"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={poster}
                    onChange={(e) => setPoster(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Datum"
                    type="date"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={releaseDate}
                    onChange={(e) => setReleaseDate(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Genre"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={genre}
                    onChange={(e) => setGenre(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="kino.de Link"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={linkKino}
                    onChange={(e) => setLinkKino(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="werstreamt.es Link"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={linkStream}
                    onChange={(e) => setLinkStream(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Dauer (in min)"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                />
                <div className={classes.iconRow}>
                    <img
                        className={isNetflix ? classes.iconImage : classes.iconImageGrey}
                        onClick={toggleNetflix}
                        src="../assets/Netflix.png"></img>
                    <img
                        className={isSky ? classes.iconImage : classes.iconImageGrey}
                        onClick={toggleSky}
                        src="../assets/Sky.webp"></img>
                    <img
                        className={isAmazon ? classes.iconImage : classes.iconImageGrey}
                        onClick={toggleAmazon}
                        src="../assets/PrimeVideo.webp"></img>
                    <img
                        className={isDisney ? classes.iconImage : classes.iconImageGrey}
                        onClick={toggleDisney}
                        src="../assets/disney.png"></img>
                </div>
            </CardContent>
            <CardActions
                sx={{ paddingTop: 0 }}
                disableSpacing>
                <IconButton
                    onClick={saveForm}
                    aria-label="edit">
                    <SaveIcon />
                </IconButton>
                <IconButton
                    onClick={() => setEditMode(movie.id ?? "", false)}
                    aria-label="close">
                    <CloseIcon />
                </IconButton>
                {movie.id && (
                    <IconButton
                        onClick={clickRemove}
                        aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                )}
            </CardActions>
        </Card>
    );
};

export default MovieEditCard;
