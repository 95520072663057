import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Button, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Book } from "../models";
import { PageContainer } from "../hoc";
import { BookStore, UserStore } from "../stores";
import { BookOverview, CategoryHeader } from "../components";
import { DateTimeHelper } from "../helpers";

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            "margin-top": "20px"
        }
    })
);

const BookOverviewContainer: FunctionComponent = () => {
    const classes = useStyles();
    const bookOverview = BookStore((state) => state.bookOverview);
    const getBookOverview = BookStore((state) => state.getBookOverview);
    const resetBookOverview = BookStore((state) => state.resetBookOverview);
    const createNewBook = BookStore((state) => state.createNewBook);
    const saveBook = BookStore((state) => state.saveBook);
    const updateBook = BookStore((state) => state.updateBook);
    const deleteBook = BookStore((state) => state.deleteBook);
    const userState = UserStore((state) => state.user);
    const [startedCollapsed, setStartedCollapsed] = useState(false);
    const [availableCollapsed, setAvailableCollapsed] = useState(true);
    const [openCollapsed, setOpenCollapsed] = useState(true);
    const [doneCollapsed, setDoneCollapsed] = useState(true);
    const [filterStarted, setFilterStarted] = useState<Book[]>([]);
    const [filterAvailable, setFilterAvailable] = useState<Book[]>([]);
    const [filterOpen, setFilterOpen] = useState<Book[]>([]);
    const [filterDone, setFilterDone] = useState<Book[]>([]);
    const [filterNew, setFilterNew] = useState<Book[]>([]);
    const openRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (bookOverview === null && userState) {
            getBookOverview(userState.uid);
        } else {
            setFilterStarted(
                bookOverview
                    ?.filter((book) => !book.isDone && book.hasStarted)
                    .sort((bookA: Book, bookB: Book) => DateTimeHelper.compareDates(bookA.startDate, bookB.startDate, bookA.releaseDate, bookB.releaseDate)) ??
                    []
            );
            setFilterAvailable(
                bookOverview
                    ?.filter((book) => (book.isLibrary || book.isOnleihe) && !(book.isDone || book.hasStarted) && isReleased(book))
                    .sort((bookA: Book, bookB: Book) => DateTimeHelper.compareDates(bookA.releaseDate, bookB.releaseDate)) ?? []
            );
            setFilterOpen(
                bookOverview
                    ?.filter((book) => (!(book.isOnleihe || book.isLibrary) && !book.isDone && book.id) || !isReleased(book))
                    .sort((bookA: Book, bookB: Book) => DateTimeHelper.compareDates(bookA.releaseDate, bookB.releaseDate)) ?? []
            );
            setFilterDone(
                bookOverview
                    ?.filter((book) => book.isDone)
                    .sort((bookA: Book, bookB: Book) =>
                        DateTimeHelper.compareDates(bookA.doneDate, bookB.doneDate, bookA.releaseDate, bookB.releaseDate, false)
                    ) ?? []
            );
            setFilterNew(bookOverview?.filter((book) => !book.id) ?? []);
        }
    }, [bookOverview, userState, getBookOverview]);

    useEffect(() => {
        return () => {
            resetBookOverview();
        };
    }, [resetBookOverview]);

    const isReleased = (book: Book) => {
        const today = new Date();
        if (!book.releaseDate) {
            return false;
        }
        const bookDate = new Date(book.releaseDate);
        return today > bookDate;
    };

    const createNewBookAction = () => {
        if (filterNew.length === 0) {
            createNewBook();
        }
    };

    return (
        <PageContainer active={3}>
            <Typography
                variant="h3"
                mt={2}
                mb={2}
                color="text.primary">
                Bücher
            </Typography>
            {(!bookOverview || bookOverview.length === 0) && (
                <Typography
                    variant="h4"
                    mt={2}
                    mb={2}
                    color="text.primary">
                    {!bookOverview ? "Bücher werden geladen..." : "Keine Bücher gefunden"}
                </Typography>
            )}
            <Button
                size="small"
                variant="outlined"
                sx={{ marginTop: "16px", marginBottom: "16px" }}
                className={classes.button}
                onClick={createNewBookAction}>
                Buch hinzufügen
            </Button>
            {bookOverview && filterNew.length > 0 && (
                <BookOverview
                    bookOverview={filterNew}
                    userId={userState?.uid ? userState?.uid : ""}
                    saveBook={saveBook}
                    updateBook={updateBook}
                    getBookOverview={getBookOverview}
                    deleteBook={deleteBook}
                />
            )}
            {bookOverview && filterStarted.length > 0 && (
                <>
                    <CategoryHeader
                        setCollapsed={setStartedCollapsed}
                        collapsed={startedCollapsed}
                        length={filterStarted.length}
                        title="Gestartet"
                    />
                    {!startedCollapsed && (
                        <BookOverview
                            bookOverview={filterStarted}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveBook={saveBook}
                            updateBook={updateBook}
                            getBookOverview={getBookOverview}
                            deleteBook={deleteBook}
                        />
                    )}
                </>
            )}
            {bookOverview && filterAvailable.length > 0 && (
                <>
                    <CategoryHeader
                        setCollapsed={setAvailableCollapsed}
                        collapsed={availableCollapsed}
                        length={filterAvailable.length}
                        title="Verfügbar"
                    />
                    {!availableCollapsed && (
                        <BookOverview
                            bookOverview={filterAvailable}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveBook={saveBook}
                            updateBook={updateBook}
                            getBookOverview={getBookOverview}
                            deleteBook={deleteBook}
                        />
                    )}
                </>
            )}
            {bookOverview && filterOpen.length && (
                <div ref={openRef}>
                    <CategoryHeader
                        setCollapsed={setOpenCollapsed}
                        collapsed={openCollapsed}
                        length={filterOpen.length}
                        title="Offen"
                    />
                    {!openCollapsed && (
                        <BookOverview
                            bookOverview={filterOpen}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveBook={saveBook}
                            updateBook={updateBook}
                            getBookOverview={getBookOverview}
                            deleteBook={deleteBook}
                        />
                    )}
                </div>
            )}
            {bookOverview && filterDone.length > 0 && (
                <>
                    <CategoryHeader
                        setCollapsed={setDoneCollapsed}
                        collapsed={doneCollapsed}
                        length={filterDone.length}
                        title="Gelesen"
                    />
                    {!doneCollapsed && (
                        <BookOverview
                            bookOverview={filterDone}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveBook={saveBook}
                            updateBook={updateBook}
                            getBookOverview={getBookOverview}
                            deleteBook={deleteBook}
                        />
                    )}
                </>
            )}
        </PageContainer>
    );
};

export default BookOverviewContainer;
