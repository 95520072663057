import React, { FunctionComponent, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {
    BookOverviewContainer,
    MovieOverviewContainer,
    LoginPageContainer,
    RegisterPageContainer,
    ResetPageContainer,
    GameOverviewContainer,
    SerieOverviewContainer
} from "../containers";
import { auth } from "../base";
import { UserStore } from "../stores";
import { ProtectedRoute } from "../hoc";
/**
 * App router
 *
 */

const AppRouter: FunctionComponent<any> = () => {
    const userState = UserStore((state) => state.user);
    const setUser = UserStore((state) => state.setUser);
    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if (!userState) {
            if (user) {
                setUser(user);
            }
        }
    }, [user, userState, setUser]);

    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={<LoginPageContainer />}
                />
                <Route
                    path="/register"
                    element={<RegisterPageContainer />}
                />
                <Route
                    path="/reset"
                    element={<ResetPageContainer />}
                />
                <Route
                    path="/movies"
                    element={
                        <ProtectedRoute
                            user={user}
                            loading={loading}>
                            <MovieOverviewContainer />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/series"
                    element={
                        <ProtectedRoute
                            user={user}
                            loading={loading}>
                            <SerieOverviewContainer />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/games"
                    element={
                        <ProtectedRoute
                            user={user}
                            loading={loading}>
                            <GameOverviewContainer />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/books"
                    element={
                        <ProtectedRoute
                            user={user}
                            loading={loading}>
                            <BookOverviewContainer />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </>
    );
};

export default AppRouter;
