import create from "zustand";
import { Game } from "../models";
import GameService from "../services/GameService";

interface State {
    gameOverview: Game[] | null;
    loadingGames: boolean;
    loadingGame: boolean;
    savingGame: boolean;
    createNewGame: () => void;
    getGameOverview: (uid: string) => Promise<any>;
    saveGame: (uid: string, game: Game) => Promise<any>;
    updateGame: (uid: string, game: Game, gameId: string) => Promise<any>;
    resetGameOverview: () => void;
    deleteGame: (uid: string, gameId: string) => Promise<any>;
}

const initGame: Game = {
    name: "",
    isPC: false,
    isSwitch: false,
    isSeries: false,
    isOne: false,
    isGamePass: false,
    hasStarted: false,
    isEdit: true,
    isDone: false
};

const useStore = create<State>((set) => ({
    gameOverview: null,
    loadingGames: false,
    loadingGame: false,
    savingGame: false,
    createNewGame: () => set((state) => ({ gameOverview: [initGame, ...(state.gameOverview ? state.gameOverview : [])] })),
    getGameOverview: async (uid: string): Promise<any> => {
        let games = await GameService.getGameOverview(uid);
        if (games) {
            games = Object.entries(games).map((game) => {
                return { ...game[1], id: game[0] };
            });
        } else {
            games = [];
        }
        set(() => ({ gameOverview: games }));
    },
    saveGame: (uid: string, game: Game) => GameService.postGame(uid, game),
    updateGame: (uid: string, game: Game, gameId: string) => GameService.updateGame(uid, game, gameId),
    resetGameOverview: () => set({ gameOverview: null }),
    deleteGame: (uid: string, gameId: string) => GameService.deleteGame(uid, gameId)
}));

export const GameStore = useStore;
