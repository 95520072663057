export class DateTimeHelper {
    public static toDateDisplay = (dateString: string | undefined) => {
        if (dateString === undefined) return "";
        const date = new Date(dateString);
        return new Intl.DateTimeFormat("de-DE", { day: "numeric", month: "long", year: "numeric" }).format(date);
    };
    public static compareDates = (
        object1: string | undefined,
        object2: string | undefined,
        objectFallback1?: string | undefined,
        objectFallback2?: string | undefined,
        descending: boolean | undefined = true
    ) => {
        if (object1 === undefined && object2 === undefined) {
            object1 = objectFallback1;
            object2 = objectFallback2;
        }
        if (object1 === undefined) return 1;
        if (object2 === undefined) return -1;
        const dateA = new Date(object1);
        const dateB = new Date(object2);
        return dateA > dateB ? (descending ? 1 : -1) : descending ? -1 : 1;
    };
    public static dateDiff = (object1: string | undefined, object2: string | undefined) => {
        if (object1 === undefined || object2 === undefined) {
            return 0;
        }
        const date1 = new Date(object1).getTime();
        const date2 = new Date(object2).getTime();
        const diff = Math.abs(date2 - date1);
        const days = Math.floor(diff / (24 * 60 * 60 * 1000));
        return days + " Tage";
    };
}
