import create from "zustand";
import { Movie } from "../models";
import MovieService from "../services/MovieService";

interface State {
    movieOverview: Movie[] | null;
    loadingMovies: boolean;
    loadingMovie: boolean;
    savingMovie: boolean;
    createNewMovie: () => void;
    getMovieOverview: (uid: string) => Promise<any>;
    saveMovie: (uid: string, movie: Movie) => Promise<any>;
    updateMovie: (uid: string, movie: Movie, movieId: string) => Promise<any>;
    resetMovieOverview: () => void;
    deleteMovie: (uid: string, movieId: string) => Promise<any>;
}

const initMovie: Movie = {
    name: "",
    isAmazon: false,
    isNetflix: false,
    isDisney: false,
    isSky: false,
    isEdit: true,
    isDone: false
};

const useStore = create<State>((set) => ({
    movieOverview: null,
    loadingMovies: false,
    loadingMovie: false,
    savingMovie: false,
    createNewMovie: () => set((state) => ({ movieOverview: [initMovie, ...(state.movieOverview ? state.movieOverview : [])] })),
    getMovieOverview: async (uid: string): Promise<any> => {
        let movies = await MovieService.getMovieOverview(uid);
        if (movies) {
            movies = Object.entries(movies).map((movie) => {
                return { ...movie[1], id: movie[0] };
            });
        } else {
            movies = [];
        }
        set(() => ({ movieOverview: movies }));
    },
    saveMovie: (uid: string, movie: Movie) => MovieService.postMovie(uid, movie),
    updateMovie: (uid: string, movie: Movie, movieId: string) => MovieService.updateMovie(uid, movie, movieId),
    resetMovieOverview: () => set({ movieOverview: null }),
    deleteMovie: (uid: string, movieId: string) => MovieService.deleteMovie(uid, movieId)
}));

export const MovieStore = useStore;
