import create from "zustand";
import { Book } from "../models";
import BookService from "../services/BookService";

interface State {
    bookOverview: Book[] | null;
    loadingBooks: boolean;
    loadingBook: boolean;
    savingBook: boolean;
    createNewBook: () => void;
    getBookOverview: (uid: string) => Promise<any>;
    saveBook: (uid: string, book: Book) => Promise<any>;
    updateBook: (uid: string, book: Book, bookId: string) => Promise<any>;
    resetBookOverview: () => void;
    deleteBook: (uid: string, bookId: string) => Promise<any>;
}

const initBook: Book = {
    name: "",
    isLibrary: false,
    isOnleihe: false,
    hasStarted: false,
    isEdit: true,
    isDone: false
};

const useStore = create<State>((set) => ({
    bookOverview: null,
    loadingBooks: false,
    loadingBook: false,
    savingBook: false,
    createNewBook: () => set((state) => ({ bookOverview: [initBook, ...(state.bookOverview ? state.bookOverview : [])] })),
    getBookOverview: async (uid: string): Promise<any> => {
        let books = await BookService.getBookOverview(uid);
        if (books) {
            books = Object.entries(books).map((book) => {
                return { ...book[1], id: book[0] };
            });
        } else {
            books = [];
        }
        set(() => ({ bookOverview: books }));
    },
    saveBook: (uid: string, book: Book) => BookService.postBook(uid, book),
    updateBook: (uid: string, book: Book, bookId: string) => BookService.updateBook(uid, book, bookId),
    resetBookOverview: () => set({ bookOverview: null }),
    deleteBook: (uid: string, bookId: string) => BookService.deleteBook(uid, bookId)
}));

export const BookStore = useStore;
