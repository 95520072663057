import React, { FunctionComponent, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { SerieEditCard, SerieViewCard } from ".";
import { Serie, SerieOverviewProps } from "../models";

/**
 * Answer Question
 */
const SerieOverview: FunctionComponent<SerieOverviewProps> = ({ serieOverview, getSerieOverview, saveSerie, updateSerie, deleteSerie, userId }) => {
    const [series, setSeries] = useState(serieOverview);

    useEffect(() => {
        setSeries(serieOverview);
    }, [serieOverview]);

    const setEditMode = (id: string, mode: boolean) => {
        setSeries((preValue) => {
            if (id === "") {
                getSerieOverview(userId);
            }
            return preValue.map((serie) => {
                return {
                    ...serie,
                    isEdit: serie.id === id ? mode : serie.isEdit
                };
            });
        });
    };

    return (
        <>
            <Grid
                container
                spacing={2}>
                {series.map((serie: Serie, index: number) => (
                    <Grid
                        item
                        key={index}
                        xs={6}
                        md={4}>
                        {serie.isEdit && (
                            <SerieEditCard
                                getSerieOverview={getSerieOverview}
                                saveSerie={saveSerie}
                                updateSerie={updateSerie}
                                setEditMode={setEditMode}
                                deleteSerie={deleteSerie}
                                serie={serie}
                                userId={userId}
                            />
                        )}
                        {!serie.isEdit && (
                            <SerieViewCard
                                serie={serie}
                                userId={userId}
                                setEditMode={setEditMode}
                                updateSerie={updateSerie}
                                getSerieOverview={getSerieOverview}
                            />
                        )}
                    </Grid>
                ))}
            </Grid>
        </>
    );
};
export default SerieOverview;
