import React, { FunctionComponent, useEffect, useState, useRef } from "react";
import { Button, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { PageContainer } from "../hoc";
import { MovieStore, UserStore } from "../stores";
import { MovieOverview, CategoryHeader } from "../components";
import { Movie } from "../models";
import { DateTimeHelper } from "../helpers";

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            "margin-top": "20px"
        },
        headline: {
            cursor: "pointer"
        }
    })
);

const MovieOverviewContainer: FunctionComponent = () => {
    const classes = useStyles();
    const movieOverview = MovieStore((state) => state.movieOverview);
    const getMovieOverview = MovieStore((state) => state.getMovieOverview);
    const resetMovieOverview = MovieStore((state) => state.resetMovieOverview);
    const createNewMovie = MovieStore((state) => state.createNewMovie);
    const saveMovie = MovieStore((state) => state.saveMovie);
    const updateMovie = MovieStore((state) => state.updateMovie);
    const deleteMovie = MovieStore((state) => state.deleteMovie);
    const userState = UserStore((state) => state.user);
    const [availableCollapsed, setAvailableCollapsed] = useState(false);
    const [openCollapsed, setOpenCollapsed] = useState(true);
    const [doneCollapsed, setDoneCollapsed] = useState(true);
    const [filterAvailable, setFilterAvailable] = useState<Movie[]>([]);
    const [filterOpen, setFilterOpen] = useState<Movie[]>([]);
    const [filterDone, setFilterDone] = useState<Movie[]>([]);
    const [filterNew, setFilterNew] = useState<Movie[]>([]);
    const openRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (movieOverview === null && userState) {
            getMovieOverview(userState.uid);
        } else {
            setFilterAvailable(
                movieOverview
                    ?.filter((movie) => (movie.isAmazon || movie.isDisney || movie.isNetflix || movie.isSky) && !movie.isDone)
                    .sort((movieA: Movie, movieB: Movie) => DateTimeHelper.compareDates(movieA.releaseDate, movieB.releaseDate)) ?? []
            );
            setFilterOpen(
                movieOverview
                    ?.filter((movie) => !(movie.isAmazon || movie.isDisney || movie.isNetflix || movie.isSky) && !movie.isDone && movie.id)
                    .sort((movieA: Movie, movieB: Movie) => DateTimeHelper.compareDates(movieA.releaseDate, movieB.releaseDate)) ?? []
            );
            setFilterDone(
                movieOverview
                    ?.filter((movie) => movie.isDone)
                    .sort((movieA: Movie, movieB: Movie) =>
                        DateTimeHelper.compareDates(movieA.doneDate, movieB.doneDate, movieA.releaseDate, movieB.releaseDate, false)
                    ) ?? []
            );
            setFilterNew(movieOverview?.filter((movie) => !movie.id) ?? []);
        }
    }, [movieOverview, userState, getMovieOverview]);

    useEffect(() => {
        return () => {
            resetMovieOverview();
        };
    }, [resetMovieOverview]);

    const createNewMovieAction = () => {
        if (filterNew.length === 0) {
            createNewMovie();
        }
    };

    return (
        <PageContainer active={0}>
            <Typography
                variant="h3"
                mt={2}
                mb={2}
                color="text.primary">
                Filme
            </Typography>
            {(!movieOverview || movieOverview.length === 0) && (
                <Typography
                    variant="h4"
                    mt={2}
                    mb={2}
                    color="text.primary">
                    {!movieOverview ? "Filme werden geladen..." : "Keine Filme gefunden"}
                </Typography>
            )}
            <Button
                size="small"
                variant="outlined"
                sx={{ marginTop: "16px", marginBottom: "16px" }}
                className={classes.button}
                onClick={createNewMovieAction}>
                Film hinzufügen
            </Button>
            {movieOverview && filterNew.length > 0 && (
                <MovieOverview
                    movieOverview={filterNew}
                    userId={userState?.uid ? userState?.uid : ""}
                    saveMovie={saveMovie}
                    updateMovie={updateMovie}
                    getMovieOverview={getMovieOverview}
                    deleteMovie={deleteMovie}
                />
            )}
            {movieOverview && filterAvailable.length > 0 && (
                <>
                    <CategoryHeader
                        setCollapsed={setAvailableCollapsed}
                        collapsed={availableCollapsed}
                        length={filterAvailable.length}
                        title="Verfügbar"
                    />
                    {!availableCollapsed && (
                        <MovieOverview
                            movieOverview={filterAvailable}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveMovie={saveMovie}
                            updateMovie={updateMovie}
                            getMovieOverview={getMovieOverview}
                            deleteMovie={deleteMovie}
                        />
                    )}
                </>
            )}
            {movieOverview && filterOpen.length > 0 && (
                <div ref={openRef}>
                    <CategoryHeader
                        setCollapsed={setOpenCollapsed}
                        collapsed={openCollapsed}
                        length={filterOpen.length}
                        title="Offen"
                    />
                    {!openCollapsed && (
                        <MovieOverview
                            movieOverview={filterOpen}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveMovie={saveMovie}
                            updateMovie={updateMovie}
                            getMovieOverview={getMovieOverview}
                            deleteMovie={deleteMovie}
                        />
                    )}
                </div>
            )}
            {movieOverview && filterDone.length > 0 && (
                <>
                    <CategoryHeader
                        setCollapsed={setDoneCollapsed}
                        collapsed={doneCollapsed}
                        length={filterDone.length}
                        title="Gesehen"
                    />
                    {!doneCollapsed && (
                        <MovieOverview
                            movieOverview={filterDone}
                            userId={userState?.uid ? userState?.uid : ""}
                            saveMovie={saveMovie}
                            updateMovie={updateMovie}
                            getMovieOverview={getMovieOverview}
                            deleteMovie={deleteMovie}
                        />
                    )}
                </>
            )}
        </PageContainer>
    );
};

export default MovieOverviewContainer;
