import ApiService from "./ApiService";
import { Serie } from "../models";

const SerieService = {
    async getSerieOverview(uid: string): Promise<Serie[]> {
        return ApiService.getData(`${uid}/serie`);
    },

    async deleteSerie(uid: string, id: string): Promise<boolean | void> {
        if (id && id !== "") {
            return ApiService.deleteData(`${uid}/serie/${id}`);
        }
    },

    async postSerie(uid: string, serie: Serie): Promise<boolean | void> {
        return ApiService.postData(`${uid}/serie`, serie);
    },

    async updateSerie(uid: string, serie: Serie, serieId: string): Promise<boolean | void> {
        return ApiService.updateData(`${uid}/serie/${serieId}`, serie);
    },

    async getSerieById(uid: string, id: string): Promise<any> {
        return ApiService.getData(`${uid}/serie/${id}`);
    }
};

export default SerieService;
